export const b2cPolicies = {
    clientId: import.meta.env.VITE_AZURE_AD_CLIENT_ID || "",
    names: {
        signUpSignIn: "B2C_1A_SIGNUP_SIGNIN",
        forgotPassword: "B2C_1A_PASSWORDRESET",
        // editProfile: "B2C_1A_profile_edit",
    },
    authorities: {
        signUpSignIn: {
            authority: import.meta.env.VITE_AZURE_AD_SIGN_IN_AUTHORITY || "",
        },
        forgotPassword: {
            authority: "",
        },
        editProfile: {
            authority: "",
        },
    },
    authorityDomain: [import.meta.env.VITE_AZURE_AD_AUTHORITY_DOMAIN || ""],
}

export const msalConfig = {
    auth: {
        clientId: b2cPolicies.clientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: b2cPolicies.authorityDomain,
        redirectUri: "/",
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: "localStorage",
    },
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [],
}
